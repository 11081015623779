/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { SEO } from "@maker-ui/seo"

import { serviceMenu } from "../config/menus"

export default () => (
  <Fragment>
    <SEO
      title="Services"
      description="Momentous Meetings offers a variety of services to its customers including, but not limited to, corporate coaching, leadership training and development, facilitation, and retreats / off-sites."
    />
    <div
      sx={{
        bg: "#000",
        minHeight: "93vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        sx={{
          m: "0 auto",
          maxWidth: 950,
          p: "0 20px 60px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          opacity: 0,
          animation: "fadeInUp ease .5s forwards .2s",
          a: {
            fontSize: [26, 75],
            fontFamily: "heading",
          },
        }}
      >
        {serviceMenu.map(({ label, path }) => (
          <ServiceLink key={path} to={path}>
            {label}
          </ServiceLink>
        ))}
      </div>
    </div>
  </Fragment>
)

const ServiceLink = styled(Link)`
  color: #fff;
  position: relative;
  margin-bottom: 30px;

  &:hover {
    &:after {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 2px solid #fff;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.2s ease-in-out;
  }
`
